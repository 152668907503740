@font-face {
    font-family: 'Suisse Intl';
    src: url('font/SuisseIntl.eot');
    src: url('font/SuisseIntl.eot?#iefix') format('embedded-opentype'),
        url('font/SuisseIntl.woff2') format('woff2'),
        url('font/SuisseIntl.woff') format('woff'),
        url('font/SuisseIntl.ttf') format('truetype'),
        url('font/SuisseIntl.svg#SuisseIntl') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Suisse Intl Light';
    src: url('font/SuisseIntl-Light.eot');
    src: url('font/SuisseIntl-Light.eot?#iefix') format('embedded-opentype'),
        url('font/SuisseIntl-Light.woff2') format('woff2'),
        url('font/SuisseIntl-Light.woff') format('woff'),
        url('font/SuisseIntl-Light.ttf') format('truetype'),
        url('font/SuisseIntl-Light.svg#SuisseIntl-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

* {
	box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Suisse Intl';
  letter-spacing: 1.2pt;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.relative {
  width: 100%;
  height: 100%;
  position: relative;
}

.table {
  width: 100%;
  height: 100%;
  display: table;
}

.table .table_cell {
  width: 100%;
  height: auto;
  display: table-cell;
  vertical-align: middle;
}

.content {
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}

.memories {
  width: 50%;
  height: 100vh;
  border-right: 2px solid #000;
  padding: 30px;
  padding-right: 45px;
  overflow: scroll;
}

.memories .title {
  font-size: 14px;
  letter-spacing: 3.4pt;
  margin-bottom: 30px;
  position: absolute;
  top: 30px;
  left: 30px;
}

.memories .title a,
.memories .title a:hover {
  color: #000;
  text-decoration: none;
}

.memories .list {
  width: 100%;
  margin-top: 46px;
}

.memories .list .memory {
  width: 100%;
  margin-bottom: 30px;
}

.memories .list .memory:last-child {
  margin-bottom: 0px;
}

.memories .list .memory .name,
.memories .list .memory .year {
  font-size: 24px;
  text-transform: uppercase;
  text-decoration: underline;
  margin-bottom: 15px;
}

.memories .list .memory .year {
  text-decoration: none;
}

.memories .list .memory .text {
  font-size: 16px;
  line-height: 1.5;
}

.memories .bar {
  width: auto;
  height: auto;
  position: fixed;
  top: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  margin-right: 5px;
}

.memories .bar .item {
  width: 100%;
  font-size: 14px;
  text-align: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.memories .bar .item:last-child {
  margin-bottom: 0px;
}

.sidebar {
  width: 50%;
  height: 100vh;
}

.sidebar .flex {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.sidebar .insert {
  width: 100%;
  flex: 1;
  overflow: hidden;
}

.sidebar .insert .slide {
  height: 100%;
  padding: 30px;
}

.sidebar .insert .slide .table_cell > div {
  position: relative;
  overflow: hidden;
}

.sidebar .insert .slide .label {
  font-size: 16px;
  text-align: center;
  margin-bottom: 30px;
}

.sidebar .insert .slide:last-child .label {
  margin-bottom: 0px;
}

.sidebar .insert .slide .textarea {
  height: auto;
  position: relative;
  display: flex;
  border-bottom: 2px solid #000;
}

.sidebar .insert .slide textarea {
  width: calc(100% - 46.81px);
  min-height: 50px;
  max-height: 100px;
  font-family: 'Suisse Intl';
  letter-spacing: 1.2pt;
  font-size: 16px;
  line-height: 2;
  flex: 1;
  border: 0px;
  border-radius: 0px;
  resize: none !important;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-right: 46.81px;
}

.sidebar .insert .slide textarea:focus {
  outline: none;
}

.sidebar .insert .slide textarea::-webkit-input-placeholder {
  text-align: center;
  padding-left: 46.81px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.sidebar .insert .slide textarea:-ms-input-placeholder {
  text-align: center;
  padding-left: 46.81px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.sidebar .insert .slide textarea::placeholder {
  text-align: center;
  padding-left: 46.81px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.sidebar .insert .slide .submit {
  width: 50px;
  height: 50px;
  font-size: 16px;
  line-height: 1.2;
  text-align: center;
  border: 2px solid #000;
  border-bottom-width: 0px;
  float: right;
  padding: 15px;
  margin-top: 30px;
  cursor: pointer;
  transition: all 0.3s linear;
  transform: translateY(50px);
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.sidebar .insert .slide .submit:hover {
  background: #000;
  color: #FFF;
}

.sidebar .stats {
  width: 100%;
  font-size: 16px;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
}

.sidebar .map {
  width: 100%;
  flex: 1;
}

.sidebar .map .dot {
  width: 10px;
  height: 10px;
  background: #000000;
  border-radius: 100%;
}

.menu {
  width: 20px;
  height: 20px;
  position: fixed;
  z-index: 999;
  right: 30px;
  top: 28px;
  border-radius: 100%;
  cursor: pointer;
	transform: scale(1);
	background: rgba(0, 67, 135, 1);
	box-shadow: 0 0 0 0 rgba(0, 67, 135, 1);
	animation: pulse 2s infinite;
}

@keyframes pulse {

	0% {
		transform: scale(0.9);
		box-shadow: 0 0 0 0 rgba(0, 67, 135, 0.5);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 67, 135, 0);
	}

	100% {
		transform: scale(0.9);
		box-shadow: 0 0 0 0 rgba(0, 67, 135, 0);
	}

}

.colophon {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 998;
  top: 0px;
  left: 0px;
  background: rgba(0, 67, 135, 0.5);
  padding: 30px;
  opacity: 0.0;
  pointer-events: none;
}

.colophon .item {
  color: #FFF;
  font-size: 16px;
  line-height: 1.2;
  text-align: center;
  margin-bottom: 30px;
}

.colophon .item:last-child {
  margin-bottom: 0px;
}

.colophon .item a {
  color: #FFF;
  text-decoration: none;
}

@media only screen and (max-width: 767px) {

  html, body, #root, .App {
    height: 100%;
  }

  .content {
    height: 100%;
    flex-direction: column;
  }

  .sidebar,
  .memories {
    width: 100%;
  }

  .memories {
    height: calc(100% - 158px);
    border-right-width: 0px;
    padding: 15px;
    padding-right: 30px;
  }

  .memories .title {
    position: relative;
    top: 0px;
    left: 0px;
    margin-bottom: 0px;
  }

  .memories .list {
    margin-top: 30px;
  }

  .memories .bar {
    right: 5px;
    top: calc(50% - 79px);
    transform: translate(0%, -50%);
  }

  .menu {
    top: 13px;
    right: 15px;
  }

  .sidebar {
    height: 158px;
    border-top: 2px solid #000;
  }

  .memories .title,
  .sidebar .insert .slide .label,
  .sidebar .insert .slide textarea {
    font-size: 14px;
  }

  .sidebar .insert .slide {
    padding: 15px;
    padding-top: 17px;
  }

  .sidebar .insert .slide textarea {
    max-height: 50px;
  }

  .map,
  .stats {
    display: none;
  }

  .colophon {
    padding: 15px;
  }

}
